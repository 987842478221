<template>
  <v-app id="talents-list" class="talents-list">
    <!--begin::Dashboard-->
    <div class="card card-custom card-stretch h-auto">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 align-items-center">
        <h3 class="card-title align-items-start flex-column m-0 pt-3">
          <span class="card-label font-weight-bolder text-dark"
            >Talent Qualifications List</span
          >
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->

      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <div
            v-if="
              qualificationsList &&
              qualificationsList.length &&
              qualificationsList.length > 0
            "
          >
            <v-alert type="info" outlined dense>
              <span class="font-size-lg">
                You Can't UnQualify Talent Who Pass Exam
              </span>
            </v-alert>
            <button
              class="btn btn-info mb-3 mr-3"
              v-if="selectedIds.length"
              @click="toggleSelectedQualifications(1)"
            >
              Qualify Selections
            </button>
            <button
              class="btn btn-danger mb-3"
              v-if="selectedIds.length"
              @click="toggleSelectedQualifications(0)"
            >
              UnQualify Selections
            </button>
          </div>
          <!--begin::Table-->
          <div
            class="table-responsive"
            v-if="
              qualificationsList &&
              qualificationsList.length &&
              qualificationsList.length > 0
            "
          >
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
                <tr class="text-left">
                  <th class="text-dark-75" style="width: 100px">
                    <div class="d-flex align-items-center">
                      <v-checkbox
                        hide-details
                        class="m-0"
                        @change="selectAll"
                        :input-value="
                          selectedIds.length &&
                          selectedIds.length === availableQualifications.length
                            ? true
                            : false
                        "
                      ></v-checkbox>
                      Select
                    </div>
                  </th>
                  <th style="min-width: 202px; max-width: 202px">Role/Skill</th>
                  <th class="px-0" colspan="3">
                    <div class="d-flex justify-content-between">
                      <div class="col-6">Assessment</div>
                      <div class="col">Score</div>
                      <div class="col">Score Status</div>
                    </div>
                  </th>
                  <th style="min-width: 110px" class="text-center">
                    Qualified
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="qualification in qualificationsList"
                  :key="qualification.id"
                >
                  <td>
                    <div
                      class="d-flex align-items-center"
                      :class="{
                        'opacity-20': !qualification.allow_qualification,
                      }"
                    >
                      <v-checkbox
                        v-model="selectedIds"
                        :value="qualification.id"
                        hide-details
                        class="m-0"
                        :disabled="!qualification.allow_qualification"
                      ></v-checkbox>
                    </div>
                  </td>
                  <td style="max-width: 202px">
                    <div class="text-dark-75 font-weight-bolder font-size-lg">
                      {{ qualification.name }}
                      <span v-if="qualification.type === 1">
                        {{ techStackNames(qualification) }}
                      </span>
                    </div>
                  </td>
                  <td colspan="3" class="font-size-lg px-0">
                    <template v-if="qualification.assessments.length">
                      <div
                        v-for="assessment in qualification.assessments"
                        :key="assessment.id"
                        class="assessment-item d-flex justify-content-between"
                      >
                        <div class="col-6">
                          <!--  :href="assessment.report"
                            target="_blank" -->
                          <span v-if="assessment.report">
                            {{ assessment.name
                            }}<span v-if="assessment.version"
                              >-V{{ assessment.version }}</span
                            >-Report
                          </span>
                          <span v-else>N/A</span>
                        </div>
                        <div class="col">
                          <span
                            class="font-weight-bolder"
                            v-if="assessment.percentage !== null"
                            >{{ assessment.percentage }}%</span
                          >
                          <span v-else>N/A</span>
                        </div>
                        <div class="col">
                          <span v-if="assessment.percentage !== null">
                            <i
                              class="fas fa-lg text-dark cursor-pointer"
                              @click="
                                handleToggleViewQualification(assessment.id)
                              "
                              :class="`${
                                assessment.view_status
                                  ? 'fa-eye'
                                  : 'fa-eye-slash'
                              }`"
                            ></i>
                          </span>
                          <span v-else>N/A</span>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="assessment-item d-flex justify-content-between"
                      >
                        <span class="col-6">N/A</span>
                        <span class="col">N/A</span>
                        <span class="col">N/A</span>
                      </div>
                    </template>
                  </td>
                  <td class="text-center" style="max-width: 110px">
                    <v-switch
                      hide-details
                      :input-value="qualification.qualified"
                      :v-model="qualification.qualified"
                      :loading="
                        loading &&
                        (activeQualification === qualification.id ||
                          selectedIds.includes(qualification.id))
                      "
                      :disabled="!qualification.allow_qualification"
                      @click="toggleSingleQualification(qualification)"
                    ></v-switch>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5
            class="my-5 text-muted text-center"
            v-if="qualificationsList && qualificationsList.length === 0"
          >
            No Results Found
          </h5>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Dashboard-->
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "TalentQualifications",
  data() {
    return {
      page: 1,
      perPage: 8,
      selectedIds: [],
      activeQualification: null,
      // qualificationsList: [
      //   {
      //     id: 1,
      //     name: "Backend",
      //     qualified: 0,
      //     type: 1,
      //     allow_qualification: true,
      //     view_status: 0,
      //     assessments: [
      //       {
      //         id: 2,
      //         name: "Dummy-test-Laravel",
      //         version: 3,
      //         tech_stack: ["PHP", "CSS", "PHP", "HTML"],
      //         percentage: null,
      //         report: "aa",
      //         view_status: 0,
      //       },
      //       {
      //         id: 13,
      //         name: "Dummy-test-Symfony",
      //         version: 1,
      //         tech_stack: ["Symfony"],
      //         percentage: 20,
      //         report: "aa",
      //         view_status: 0,
      //       },
      //     ],
      //   },
      //   {
      //     id: 288,
      //     name: "Symfony",
      //     qualified: 0,
      //     type: 2,
      //     allow_qualification: true,
      //     view_status: 0,
      //     assessments: [
      //       {
      //         id: 13,
      //         name: "Dummy-test-Symfony",
      //         version: 1,
      //         tech_stack: ["Symfony"],
      //         percentage: null,
      //         report: "aa",
      //         view_status: 0,
      //       },
      //     ],
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      qualificationsList: "talentProfile/getQualificationsList",
    }),
    availableQualifications() {
      return this.qualificationsList?.filter(
        (item) => item.allow_qualification
      );
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Talent Qualifications" },
    ]);
  },
  created() {
    this.getQualificationsList(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      getQualificationsList: "talentProfile/getQualificationsList",
      toggleQualification: "talentProfile/toggleQualification",
      toggleViewQualification: "talentProfile/toggleViewQualification",
    }),
    toggleSingleQualification(qualification) {
      this.activeQualification = qualification.id;
      let payload = {
        talent_id: this.$route.params.id,
        technologies: [qualification.id],
        qualified: qualification.qualified ? 0 : 1,
      };
      this.toggleQualification(payload).then(() => {
        this.activeQualification = null;
      });
    },
    toggleSelectedQualifications(qualified) {
      Swal.fire({
        title: "Are you sure",
        text: `you want to ${
          qualified ? "qualify" : "Disqualify"
        } Selected Role/Skills for that Talent?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            talent_id: this.$route.params.id,
            technologies: this.selectedIds,
            qualified,
          };
          this.toggleQualification(payload).then(() => {
            this.selectedIds = [];
          });
        }
      });
    },
    selectAll(value) {
      if (value) {
        this.selectedIds = this.availableQualifications.map((item) => item.id);
      } else {
        this.selectedIds = [];
      }
    },
    handleToggleViewQualification(qualificationId) {
      let payload = {
        talentId: this.$route.params.id,
        qualificationId,
      };
      this.toggleViewQualification(payload);
    },
    techStackNames(qualification) {
      if (!qualification.assessments.length) {
        return "";
      }
      let tech_stack = [];
      qualification.assessments.forEach((assessment) => {
        tech_stack.push(...assessment.tech_stack);
      });
      // remove duplicated stacks
      tech_stack = Array.from(new Set(tech_stack));
      let techStackNames = tech_stack.toString().replaceAll(",", ", ");
      return qualification.name !== techStackNames ? `(${techStackNames})` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.table.table-vertical-center {
  th {
    color: #3f4254 !important;
  }
  td {
    padding-top: 16px;
    vertical-align: top;
    .assessment-item {
      a:hover {
        opacity: 0.8;
      }
      &:not(:last-of-type) {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid lighten($color: $purpleColor, $amount: 25%);
      }
    }
  }
}
::v-deep {
  .v-input--selection-controls .v-input__slot {
    justify-content: center;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
