var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"talents-list",attrs:{"id":"talents-list"}},[_c('div',{staticClass:"card card-custom card-stretch h-auto"},[_c('div',{staticClass:"card-header border-0 py-5 align-items-center"},[_c('h3',{staticClass:"card-title align-items-start flex-column m-0 pt-3"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v("Talent Qualifications List")])])]),_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('div',{staticClass:"tab-content"},[(
            _vm.qualificationsList &&
            _vm.qualificationsList.length &&
            _vm.qualificationsList.length > 0
          )?_c('div',[_c('v-alert',{attrs:{"type":"info","outlined":"","dense":""}},[_c('span',{staticClass:"font-size-lg"},[_vm._v(" You Can't UnQualify Talent Who Pass Exam ")])]),(_vm.selectedIds.length)?_c('button',{staticClass:"btn btn-info mb-3 mr-3",on:{"click":function($event){return _vm.toggleSelectedQualifications(1)}}},[_vm._v(" Qualify Selections ")]):_vm._e(),(_vm.selectedIds.length)?_c('button',{staticClass:"btn btn-danger mb-3",on:{"click":function($event){return _vm.toggleSelectedQualifications(0)}}},[_vm._v(" UnQualify Selections ")]):_vm._e()],1):_vm._e(),(
            _vm.qualificationsList &&
            _vm.qualificationsList.length &&
            _vm.qualificationsList.length > 0
          )?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless"},[_c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',{staticClass:"text-dark-75",staticStyle:{"width":"100px"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-checkbox',{staticClass:"m-0",attrs:{"hide-details":"","input-value":_vm.selectedIds.length &&
                        _vm.selectedIds.length === _vm.availableQualifications.length
                          ? true
                          : false},on:{"change":_vm.selectAll}}),_vm._v(" Select ")],1)]),_c('th',{staticStyle:{"min-width":"202px","max-width":"202px"}},[_vm._v("Role/Skill")]),_c('th',{staticClass:"px-0",attrs:{"colspan":"3"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"col-6"},[_vm._v("Assessment")]),_c('div',{staticClass:"col"},[_vm._v("Score")]),_c('div',{staticClass:"col"},[_vm._v("Score Status")])])]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"110px"}},[_vm._v(" Qualified ")])])]),_c('tbody',_vm._l((_vm.qualificationsList),function(qualification){return _c('tr',{key:qualification.id},[_c('td',[_c('div',{staticClass:"d-flex align-items-center",class:{
                      'opacity-20': !qualification.allow_qualification,
                    }},[_c('v-checkbox',{staticClass:"m-0",attrs:{"value":qualification.id,"hide-details":"","disabled":!qualification.allow_qualification},model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}})],1)]),_c('td',{staticStyle:{"max-width":"202px"}},[_c('div',{staticClass:"text-dark-75 font-weight-bolder font-size-lg"},[_vm._v(" "+_vm._s(qualification.name)+" "),(qualification.type === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.techStackNames(qualification))+" ")]):_vm._e()])]),_c('td',{staticClass:"font-size-lg px-0",attrs:{"colspan":"3"}},[(qualification.assessments.length)?_vm._l((qualification.assessments),function(assessment){return _c('div',{key:assessment.id,staticClass:"assessment-item d-flex justify-content-between"},[_c('div',{staticClass:"col-6"},[(assessment.report)?_c('span',[_vm._v(" "+_vm._s(assessment.name)),(assessment.version)?_c('span',[_vm._v("-V"+_vm._s(assessment.version))]):_vm._e(),_vm._v("-Report ")]):_c('span',[_vm._v("N/A")])]),_c('div',{staticClass:"col"},[(assessment.percentage !== null)?_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(assessment.percentage)+"%")]):_c('span',[_vm._v("N/A")])]),_c('div',{staticClass:"col"},[(assessment.percentage !== null)?_c('span',[_c('i',{staticClass:"fas fa-lg text-dark cursor-pointer",class:`${
                              assessment.view_status
                                ? 'fa-eye'
                                : 'fa-eye-slash'
                            }`,on:{"click":function($event){return _vm.handleToggleViewQualification(assessment.id)}}})]):_c('span',[_vm._v("N/A")])])])}):[_c('div',{staticClass:"assessment-item d-flex justify-content-between"},[_c('span',{staticClass:"col-6"},[_vm._v("N/A")]),_c('span',{staticClass:"col"},[_vm._v("N/A")]),_c('span',{staticClass:"col"},[_vm._v("N/A")])])]],2),_c('td',{staticClass:"text-center",staticStyle:{"max-width":"110px"}},[_c('v-switch',{attrs:{"hide-details":"","input-value":qualification.qualified,"v-model":qualification.qualified,"loading":_vm.loading &&
                      (_vm.activeQualification === qualification.id ||
                        _vm.selectedIds.includes(qualification.id)),"disabled":!qualification.allow_qualification},on:{"click":function($event){return _vm.toggleSingleQualification(qualification)}}})],1)])}),0)])]):_vm._e(),(_vm.qualificationsList && _vm.qualificationsList.length === 0)?_c('h5',{staticClass:"my-5 text-muted text-center"},[_vm._v(" No Results Found ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }